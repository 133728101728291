.postsContainer {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.postsContainer input[type="text"],
.postsContainer input[type="file"] {
    display: block;
    margin: 10px 0;
    padding: 8px;
    width: 40%;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.postsContainer textarea {
    display: block;
    margin: 10px 0;
    padding: 8px;
    width: 90%;
    height: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

label {
    display: block;
    margin-top: 15px;
    font-weight: bold;
}

.postsEntry {
    padding: 10px;
    border: 1px solid #eee;
    margin-top: 15px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.addButton,
.deleteButton {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px 15px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.deleteButton {
    background-color: #dc3545;
}

.addButton:hover,
.deleteButton:hover {
    opacity: 0.9;
}
.posts-members {
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
}

.posts-members > div {
    flex: 0 0 49.5%;
}

@media (max-width: 767px) {
    .posts-members > div {
        flex: 0 0 100%;
    }
}
