.person-card {
    text-align: center;
}

.name-title {
    background: linear-gradient(180deg, #03ffff 0%, #10ffbb 100%);
    text-align: center;
}

.name-title > h4 {
    font-size: 0.75rem;
    padding-bottom: 10px;
}

.name-title > h3 {
    font-family: organetto-variable, sans-serif;
    font-size: 1.25rem;
    padding-top: 10px;
    margin-bottom: 0px !important;
    font-variation-settings: "wdth" 50, "wght" 75;
}

.person-img {
    height: 50vh;
}

.person-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
}

@media (max-width: 767px) {
    .name-title > h3 {
        font-size: 1rem;
    }
}
