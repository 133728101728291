.team-members {
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
}

.team-members > div {
    flex: 0 0 49.5%;
}

@media (max-width: 767px) {
    .team-members > div {
        flex: 0 0 100%;
    }
}
