.contact-container {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.contact-container h2 {
    font-size: 1.25rem;
}

.socials,
.contact-details {
    padding-top: 30px;
}

.contact-subcontainer p {
    font-size: 0.875rem;
    margin-bottom: 8px;
}

.contact-container {
    display: flex;
}

.contact-container > div {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 0px !important;
    padding-left: 5%;
    padding-right: 5%;
    flex: 0 0 50%;
}

.contactForm {
    flex-grow: 1;
    text-align: center;
    max-width: 700px;
    background-color: var(--stefi-fens-white);
    min-height: 100%;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
    border-radius: var(--border-radius);
    align-self: center;
}

.contactFormFields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contactFormFields .submitButton {
    align-self: flex-start;
    height: 2rem;
    width: 120px;
    min-width: 120px;
    border: none;
    outline: none;
    background: black;
    color: white;
    border-radius: 40px;
    box-shadow: 0 6px, 20px, -5px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    padding-top: 2px;
}

.contactFormFields .submitButton:hover {
    transition-duration: 0.1s;
    background: linear-gradient(270deg, #10ffbb 0%, #03ffff 100%);
    color: #000;
}

.contactFormFields .submitButton:disabled {
    align-self: flex-start;
    height: 2rem;
    width: 120px;
    min-width: 120px;
    border: none;
    outline: none;
    background: gray;
    color: #fff;
    border-radius: 20px;
    box-shadow: 0 6px, 20px, -5px rgba(0, 0, 0, 0.4);
    padding-top: 2px;
}

.contactFormFields :global(input, label) {
    flex-basis: 1 1 100%;
}

.contactFormFields > .checkboxArea {
    text-align: left;
}

.labelAndError {
    display: flex;
    justify-content: space-between;
}

.labels {
    font-weight: bold;
    padding-left: 0.5rem;
}

.labelAndError > label {
    margin: 0;
}

.inputField,
.message {
    border-width: 1px;
    border-radius: 20px;
    border-color: #000;
    padding: 0.5rem;
    width: 100%;
    -webkit-appearance: none;
}

.message {
    height: 150px;
}

.formTitle {
    padding: 2rem 2rem 0rem 2rem;
    font-weight: 500;
    text-align: left;
}

.link {
    color: var(--text-color-primary);
}

.checkboxArea > label {
    margin-top: 0;
}

.checkboxText {
    font-size: 0.75rem;
    text-align: left;
}

.errorMsgArea {
    display: flex;
}

.errorMsg {
    padding: 0;
    color: red;
    text-align: right;
    flex: 1 1 70%;
    margin: 0;
}

.checkboxText > .privacyLink {
    color: var(--stefi-fens-dark-green);
    font-weight: 600;
}

@media (max-width: 767px) {
    .contact-container {
        flex-direction: column;
    }

    .contact-container > div {
        flex: 0 0 100%;
    }
}
