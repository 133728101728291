.App-container {
    width: 100%;
    height: 100%;
    position: relative;
    flex: 1;
}

nav .navbarelements,
.navbar-toggler {
    font-size: 0.875rem;
    line-height: 1rem;
    padding: 8px 0;
}

.navbarelements > img {
    position: relative;
    top: -1px;
}

.auto-width {
    width: auto;
    max-width: 100%;
    justify-content: space-between;
}

.flex-grow {
    flex-grow: 1;
}

.navbar-items {
    margin-left: 30px;
    margin-right: 30px;
    align-items: center;
}

.rotated-text {
    position: fixed;
    bottom: 250px;
    right: 1%;
    margin-bottom: 0px !important;
    transform-origin: bottom right;
    transform: rotate(-90deg);
    font-size: 0.875rem;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rotated-text-white {
    position: fixed;
    bottom: 30%;
    right: 5%;
    transform-origin: bottom right;
    transform: rotate(-90deg);
    font-size: 0.875rem;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 767px) {
    .rotated-text {
        bottom: 40%;
        right: 0px;
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
    .rotated-text-white {
        bottom: 20%;
        right: 1%;
    }
}