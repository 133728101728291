
.footer-lg-screen {
    padding: 0 10% 16px;
    width: 100%;
    font-size: 0.75rem;
    display: flex;
    justify-content: space-between;
  }
  
  .footer-sm-screen {
    padding: 0 10% 16px;
    width: 100%;
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 0.75rem;
  }
  
  footer span,
  footer a {
    position: relative;
    top: -2px;
  }
  
  footer a:link,
  footer a:visited {
    color: #333;
    text-decoration: none;
  }
  