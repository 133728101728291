.post {
    padding: 30px 5% 30px 5%;
}

.post-title > h2 {
    margin-bottom: 0;
}

.post-timestamp {
    margin-top: 0.5rem;
    font-style: italic;
}

.post-text {
    margin-top: 1rem;
}

.post-text > pre {
    text-wrap: wrap;
    font-family: Arial, Helvetica, sans-serif;
}

pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.post-image {
    margin-top: 1rem;
}

.post-image > img {
    max-width: 100%;
}
