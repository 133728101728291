.services-editor-form {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .services-editor-form input[type="text"],
  .services-editor-form input[type="file"] {
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .services-editor-form button {
    margin-bottom: 20px;
  }