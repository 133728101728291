.hero-banner-section {
    min-width: 100%;
    max-width: 100%;
    position: relative;
    height: 20vh;
    text-align: center;
}

.hero-banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-variation-settings: 'wdth' 50, 'wght' 100;
}

.hero-banner-section img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}