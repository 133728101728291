.products {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
}

.products > div {
    flex: 0 1 32%;
}

.call-us {
    padding: 60px 10% 0 10%;
    font-family: Campton, sans-serif;
    text-align: center;
}

.call-us span {
    position: relative;
    top: -2px;
}

.products-navigation {
    display: flex;
    justify-content: space-between;
}

.products-navigation > .products-categories > .dropdown {
    padding: 0 0 20px 0;
    margin: 0;
}

.products-navigation > .products-categories > .dropdown > button {
    width: 175px;
    background: linear-gradient(180deg, #03ffff 0%, #10ffbb 100%);
    color: black;
    border-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 767px) {
    .products > div {
        flex: 1 1 49.5%;
    }

    .call-us {
        padding-top: 30px;
        font-size: 0.75rem;
    }

    .products-navigation > .products-categories > .dropdown {
        padding: 0 0 10px 0;
    }

    .products-navigation > .products-categories > .dropdown > button {
        font-size: 0.75rem;
        width: 125px;
    }

    .products-search > form > input {
        font-size: 0.75rem;
        width: 125px;
    }
}
