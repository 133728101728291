.Posts {
    display: flex;
    justify-content: center;
}

.posts-navigation {
    flex: 1 0 30%;
    padding-right: 5%;
}

.posts-navigation > h2,
.offcanvas-body > h2 {
    font-size: 1.125rem;
    margin-bottom: 30px;
}

.posts-navigation h3,
.offcanvas-body h3 {
    font-size: 0.875rem;
    font-weight: normal;
    cursor: pointer;
}

.posts-navigation h3:hover,
.offcanvas-body h3:hover {
    font-weight: bold;
}

.post-display {
    flex: 1 0 70%;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.accordion-header > .accordion-button {
    font-size: 0.875rem;
    padding: 8px 16px;
    border-radius: 5px;
}

.accordion-item > .accordion-collapse > .accordion-body {
    font-size: 0.875rem;
    padding: 8px 16px;
    border-radius: 5px;
}

.posts-header {
    margin-top: 60px;
}

.divider {
    content: "";
    border-bottom: 1px;
}

.Posts > .posts-navigation > button {
    background: linear-gradient(270deg, #10ffbb 0%, #03ffff 100%);
    border-color: black;
    color: black;
}

@media (max-width: 767px) {
    .Posts {
        flex-direction: column;
        gap: 16px;
    }
}
