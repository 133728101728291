.Home {
    background: url("../images/homepage-bg.jpeg") no-repeat;
    background-size: cover;
    height: calc(100vh - 49px);
}

.Home .lander {
    padding-left: 5%;
    text-align: left;
}

.hero-title {
    font-size: 2.25rem;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.125rem;

    background: linear-gradient(270deg, #10ffbb 0%, #03ffff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: organetto-variable, sans-serif;
    font-variation-settings: "wdth" 50, "wght" 75;
}

.hero-subtitle {
    padding-top: 15%;

    font-size: 1.75rem;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5rem;

    background: linear-gradient(270deg, #10ffbb 0%, #03ffff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: organetto-variable, sans-serif;
    font-variation-settings: "wdth" 50;
}

.lander {
    height: 100%;
}

.hero {
    padding-top: 10%;
}

.expanded-letter {
    font-variation-settings: "wdth" 100, "wght" 75;
}

.bolded-words {
    font-variation-settings: "wdth" 50, "wght" 100;
}

@media (max-width: 767px) {
    .Home {
        background-position: center;
        transition: background-position 2s ease;
    }
    .hero-title {
        font-size: 1.5rem;
        letter-spacing: 0.875rem;
        margin-left: 0;
        letter-spacing: 0.75rem;
    }
    .hero-subtitle {
        font-size: 1.25rem;
        letter-spacing: 0.25rem;
        padding-top: 25%;
    }
}
