.container {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-justify: inter-word;
}

@media (max-width: 767px) {
    .container {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}