.product-card {
    text-align: center;
}

.prod-name {
    background: linear-gradient(180deg, #03ffff 0%, #10ffbb 100%);
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prod-name > h4 {
    font-size: 0.75rem;
    padding-bottom: 10px;
}

.prod-name > h3 {
    font-family: organetto-variable, sans-serif;
    font-size: 1.25rem;
    padding: 5px 0 5px 0;
    margin-bottom: 0px !important;
    font-variation-settings: "wdth" 50, "wght" 50;
}

.product-img {
    height: 350px;
    position: relative;
    background-color: rgb(241, 241, 244);
}

.product-img img {
    height: 100%;
    width: 100%;
    /* object-fit: cover;
  object-position: center; */
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product-price {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 16px;
}

.product-description {
    position: absolute;
    top: 4px;
    left: 8px;
}

@media (max-width: 767px) {
    .prod-name > h3 {
        font-size: 1rem;
    }
}
