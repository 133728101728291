.services-table {
    font-size: 1rem;
}

.services-table-header {
    border-top: none !important;
}

.narrow-table-col {
    width: 1px;
}

.info {
    background: linear-gradient(180deg, #03ffff 0%, #10ffbb 100%);
    color: #000; /* Black text color */
    padding: 20px; /* Spacious padding */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow for depth */
    margin-bottom: 16px;
    width: 100%;
    display: flex;
}

.info > span {
    padding: 0 0.5rem;
}

section > div.tab-content {
    overflow: auto;
}

@media (max-width: 767px) {
    .info {
        padding: 10px;
        font-size: 0.75rem;
    }
    
    .services-table {
        font-size: 0.75rem;
    }

    .section > ul.nav-tabs {
        font-size: 0.75rem;
    }
}