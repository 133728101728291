@font-face {
    font-family: organetto-variable;
    src: url('./fonts/Organetto-Variable.ttf') format('truetype');
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

body {
    margin: 0;
    padding: 0;
    color: #333;
    font-size: 16px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: "Campton", sans-serif;
    line-height: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Campton", sans-serif;
}

select.form-control,
textarea.form-control,
input.form-control {
    font-size: 1rem;
}
input[type="file"] {
    width: 100%;
}

.subtitle {
    font-family: organetto-variable, sans-serif;
    font-variation-settings: "wdth" 50, "wght" 50;
    font-size: 1.5rem;

    background: linear-gradient(270deg, #10ffbb 0%, #03ffff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.textparagraph {
    font-family: Campton, sans-serif;
    color: black;
    margin-bottom: 24px;
}

.Privacy h1 {
    margin-left: 0px;
}
